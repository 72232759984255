import { Component, OnInit } from '@angular/core';
import { Amplify } from '@aws-amplify/core';

import { environment } from '../environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor() {
        // Configure Amplify Auth settings
        Amplify.configure(environment.cognitoConfiguration);
    }

    ngOnInit() {
    }
}
