import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginResult, LoginService } from '../services';

@Injectable({providedIn: 'root'})
export class AuthGuard  {

    constructor(private loginService: LoginService, private router: Router) { }

    canActivate(): Observable<boolean | UrlTree> {
        return new Observable(obs => {
            this.loginService.isAuthenticated().subscribe({
                next: (result: LoginResult) => {
                    if (result === LoginResult.LOGINSUCCESS) {
                        obs.next(true);
                    } else {
                        // User not logged in. Redirect to login page
                        obs.next(this.router.parseUrl('/login'));
                    }
                },
                error: (err: any) => {
                    console.error('[auth.guard]', err);
                    obs.next(false);
                }
            });
        });
    }
}
