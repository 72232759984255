import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Auth } from '@aws-amplify/auth';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { from, Observable } from 'rxjs';
import { map, publishReplay, refCount, switchMap } from 'rxjs/operators';

import { User, UserAdapter } from '../models';
import { environment } from '../../../environments/environment';
import Bugsnag from '@bugsnag/js';

export enum LoginResult {
    LOGINSUCCESS,
    LOGINFAILURE,
    INVALIDCREDENTIALS,
    RESETPASSWORD,
    SETNEWPASSWORD,
    MFACHALLENEGE
}

@Injectable({
    providedIn: 'root'
})
export class LoginService {
    private userApi: string;
    private users: Observable<User[]>;

    constructor(private http: HttpClient, private userAdapter: UserAdapter, private router: Router) {
        this.userApi = environment.userAdminApiRoot;
    }

    private _wrapLoginObservable(promise: Promise<any>): Observable<LoginResult> {
        return new Observable(observer => {
            promise.then((user: any) => {
                if (user && user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    observer.next(LoginResult.SETNEWPASSWORD);
                } else if (user && user.challengeName === 'MFA') {
                    // TODO determine correct value
                    observer.next(LoginResult.MFACHALLENEGE);
                } else {
                    // Sometimes no result is returned, even though the action was successful
                    // For example, this happens when resetting password via forgot workflow
                    observer.next(LoginResult.LOGINSUCCESS);

                    // Set user details for Bugsnag reporting
                    if (user instanceof CognitoUserSession) {
                        const idToken = (user as CognitoUserSession).getIdToken();
                        const email = ('email' in idToken.payload) ? idToken.payload.email : null;
                        const name = ('name' in idToken.payload) ? idToken.payload.name : null;
                        Bugsnag.setUser(idToken.payload.sub, email, name);
                    }
                }
            })
            .catch((err: any) => {
                if (err.code === 'PasswordResetRequiredException') {
                    observer.next(LoginResult.RESETPASSWORD);
                } else if (err.code === 'NotAuthorizedException') {
                    observer.next(LoginResult.INVALIDCREDENTIALS);
                } else {
                    observer.next(LoginResult.LOGINFAILURE);
                }
            })
            .finally(() => observer.complete());
        });
    }

    private _wrapBooleanObservable(promise: Promise<any>, verifyMethod: (result: any) => boolean): Observable<boolean> {
        return new Observable<boolean>(observer => {
            promise
                .then((result: any) => observer.next(verifyMethod(result)))
                .catch(() => observer.next(false))
                .finally(() => observer.complete());
        });
    }

    public authenticate(username: string, password: string): Observable<LoginResult> {
        return this._wrapLoginObservable(Auth.signIn(username, password));
    }

    public isAuthenticated(): Observable<LoginResult> {
        return this._wrapLoginObservable(Auth.currentSession());
    }

    public isAuthenticatedAdmin(): Observable<boolean> {
        return this._wrapBooleanObservable(Auth.currentSession(), (result) => {
            const token = (result as CognitoUserSession).getIdToken();
            if ('cognito:groups' in token.payload) {
                const groups: string[] = token.payload['cognito:groups'];
                return groups.includes('Administrators');
            }
            return false;
        });
    }

    public confirmNewPassword(username: string, oldPassword: string, newPassword: string): Observable<LoginResult> {
        return from(Auth.signIn(username, oldPassword)).pipe(
            switchMap(user => this._wrapLoginObservable(Auth.completeNewPassword(user, newPassword)))
        );
    }

    public initiateForgotPassword(username: string): Observable<LoginResult> {
        return this._wrapLoginObservable(Auth.forgotPassword(username));
    }

    public resetPassword(username: string, verifyCode: string, newPassword: string): Observable<LoginResult> {
        return this._wrapLoginObservable(Auth.forgotPasswordSubmit(username, verifyCode, newPassword));
    }

    public changePassword(oldPassword: string, newPassword: string): Observable<LoginResult> {
        return from(Auth.currentAuthenticatedUser()).pipe(
            switchMap(user => this._wrapLoginObservable(Auth.changePassword(user, oldPassword, newPassword)))
        );
    }

    public getAPIToken(): Observable<string> {
        return new Observable<string>(obs => {
            Auth.currentSession().then(
                session => {
                    if (session) {
                        const token = session.getIdToken().getJwtToken();
                        obs.next(token);
                    } else {
                        obs.next(null);
                    }
                }
            )
            .catch(() => obs.next(null))
            .finally(() => obs.complete());
        });
    }

    public getUserAttributes(): Observable<User> {
        return new Observable<User>(obs => {
            Auth.currentUserInfo().then(
                info => {
                    if (info) {
                        obs.next(this.userAdapter.adapt(info));
                    } else {
                        obs.next(null);
                    }
                }
            )
            .catch(() => obs.next(null))
            .finally(() => obs.complete());
        });
    }

    public updateUserAttributes(updateAttributes: any): Observable<boolean> {
        return from(Auth.currentAuthenticatedUser()).pipe(
            switchMap(user => {
                return this._wrapBooleanObservable(
                    Auth.updateUserAttributes(user, updateAttributes), (result) => result === 'SUCCESS'
                );
            })
        );
    }

    public resendAttributeVerification(attribute: string): Observable<boolean> {
        return this._wrapBooleanObservable(Auth.verifyCurrentUserAttribute(attribute), (result) => result === 'SUCCESS');
    }

    public verifyAttribute(attribute: string, verifyCode: string): Observable<boolean> {
        return this._wrapBooleanObservable(Auth.verifyCurrentUserAttributeSubmit(attribute, verifyCode), (result) => result === 'SUCCESS');
    }

    public logout(): void {
        Auth.signOut().then(() => {
            this.router.navigate(['/login']);
        });
    }

    public getAllUsers(): Observable<User[]> {
        if (!this.users) {
            this.users = this.http.get<any>(`${this.userApi}/listUserNames`).pipe(
                map(response => response.Users.map((item: any) => this.userAdapter.adapt(item))),
                publishReplay(1),
                refCount()
            );
        }
        return this.users;
    }

    public clearCachedUsers(): void {
        this.users = null;
    }
}
