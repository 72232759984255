import { Injectable } from '@angular/core';
import { Adapter } from './adapter';
import { ALL_DCF_OFFICES } from './values/office.model';

interface ISocialWorker {
    name: string;
    phone: string;
    email: string;
    officeId: number;
    officeOther: string;
}

export class SocialWorker {
    public name: string;
    public phone: string;
    public email: string;
    public DCFAreaOfficeId: number;
    public DCFAreaOther: string;

    constructor(obj?: ISocialWorker) {
        this.name = obj && obj.name || '';
        this.phone = obj && obj.phone || '';
        this.email = obj && obj.email || '';
        this.DCFAreaOfficeId = obj && obj.officeId || -1;
        this.DCFAreaOther = obj && obj.officeOther || '';
    }

    get DCFAreaOffice(): string {
        if (this.DCFAreaOfficeId > 0) {
            if (this.DCFAreaOfficeId === 31) {
                return this.DCFAreaOther;
            } else {
                return ALL_DCF_OFFICES.find(o => o.id === this.DCFAreaOfficeId).name;
            }
        }
        return '';
    }
}

@Injectable()
export class SocialWorkerAdapter implements Adapter<SocialWorker> {

  adapt(item: any): SocialWorker {
    return new SocialWorker({
        name: item && item.name || '',
        phone: item && item.phone || '',
        email: item && item.email || '',
        officeId: item && item.officeId || -1,
        officeOther: item && item.officeOther || ''
    });
  }
}
