import { Injectable } from '@angular/core';
import { Adapter } from './adapter';
import { ALL_CATEGORIES } from './values/category.model';

interface IDecision {
    isApproved: boolean;
    amount: string;
    decisionBy: string;
    categoryId: number;
    approvedActivity: string;
    timestamp: Date;
}

export class Decision {
    public isApproved: boolean;
    public amount: string;
    public decisionBy: string;
    public categoryId: number;
    public approvedActivity: string;
    public timestamp: Date;

    constructor(obj?: IDecision) {
        this.isApproved = (obj && 'isApproved' in obj) ? obj.isApproved : null;
        this.amount = obj && obj.amount || '';
        this.decisionBy = obj && obj.decisionBy || '';
        this.categoryId = obj && obj.categoryId || -1;
        this.approvedActivity = obj && obj.approvedActivity || '';
        this.timestamp = obj && obj.timestamp || null;
    }

    get category() {
        if (this.categoryId > 0) {
            return ALL_CATEGORIES.find(c => c.id === this.categoryId).name;
        }
        return '';
    }
}

@Injectable()
export class DecisionAdapter implements Adapter<Decision> {

  adapt(item: any): Decision {
    return new Decision({
        isApproved: (item && 'isApproved' in item) ? item.isApproved : null,
        amount: item && item.amount || '',
        decisionBy: item && item.decisionBy || '',
        categoryId: item && item.categoryId || -1,
        approvedActivity: item && item.approvedActivity || '',
        timestamp: (item && item.timestamp) ? new Date(item.timestamp) : null
    });
  }
}
