<div class="row">
    <div class="col-xl-12">
        <h2 class="page-header">
            {{ heading | translate }}
        </h2>
        <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item">
                <i class="fas fa-fw fa-tachometer-alt"></i>
                <a href="#" [routerLink]="['/dashboard']">{{ 'Dashboard' | translate }}</a>
            </li>
            <li class="breadcrumb-item" *ngFor="let breadcrumb of breadcrumbs">
                <i class="fas fa-fw fa-tachometer-alt" [className]="'fas fa-fw fa-'+breadcrumb.icon"></i>
                <a *ngIf="breadcrumb.route; else noLink" href="#" [routerLink]="breadcrumb.route">{{ breadcrumb.name | translate }}</a>
                <ng-template #noLink>
                    <span>{{ breadcrumb.name | translate }}</span>
                </ng-template>
            </li>
        </ol>
    </div>
</div>
