import { Constant } from './constant';

export const ALL_GENDERS: Constant[] = [
    { id: 1, name: 'Female' },
    { id: 3, name: 'Male' },
    { id: 2, name: 'Transgender' },
    { id: 4, name: 'Transgender (female to male)' },
    { id: 5, name: 'Transgender (male to female)' },
    { id: 6, name: 'Androgynous' },
    { id: 7, name: 'Gender nonconforming' },
    { id: 8, name: 'Genderqueer' },
    { id: 9, name: 'Non-binary' },
    { id: 10, name: 'Questioning' },
    { id: 11, name: 'Two spirit' },
    { id: 12, name: 'Does not wish to answer' },
    { id: 13, name: 'Not listed above' },
    { id: -1, name: 'Not Specified' },
];
