import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, expand, map, reduce } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Note, NoteAdapter } from '../models';


@Injectable()
export class NoteService {
    private api: string;

    constructor(private http: HttpClient, private adapter: NoteAdapter) {
        this.api = `${environment.apiRoot}${environment.apiEndpoints.fundingRequests}/{:id}${environment.apiEndpoints.notes}`;
    }

    /**
     * Get all Funding Requests with a given status
     */
    list(requestId: string): Observable<Note[]> {
        const endpoint = this.api.replace('{:id}', requestId);
        return new Observable((observer: any) => {
            this.getItems(endpoint).pipe(
                expand((data, i) => {
                    return data.next ? this.getItems(endpoint, data.next) : of();
                }),
                reduce((acc, data) => {
                    return acc.concat(data.results);
                }, []),
                catchError(error => observer.error(error))
            )
            .subscribe((notes: Note[]) => {
                observer.next(notes);
                observer.complete();
            });
        });
    }

    private getItems(endpoint: string, startKey: string = null): Observable<{next: string, results: Note[]}> {
        if (startKey) {
            endpoint += `?startKey=${startKey}`;
        }
        return this.http.get<any>(endpoint).pipe(
            map(response => {
                const nextId = ('lastEvaluatedKey' in response) ? response.lastEvaluatedKey : null;
                return {
                    next: nextId,
                    results: response.notes.map((item: any) => this.adapter.adapt(item))
                };
            })
        );
    }

    /**
     * Get a single Note for a given Funding Request
     */
    get(requestId: string, noteId: number): Observable<Note> {
        const endpoint = `${this.api.replace('{:id}', requestId)}/${noteId}`;
        return requestId && noteId ?
            this.http.get<any>(endpoint).pipe(
                // Adapt each item in the raw data array to a FundingRequest object
                map(response => this.adapter.adapt(response.note)),
            ) :
            of<Note>();
    }

    /**
     * Create a new Note for a given Funding Request
     */
    put(requestId: string, note: Note): Observable<Note> {
        const endpoint = this.api.replace('{:id}', requestId);
        const data = {
            comment: note.comment,
            username: note.userId,
            timestamp: note.date.getTime()
        };
        return new Observable((observer: any) => {
            this.http.put<any>(endpoint, data).pipe(
                catchError(error => observer.error(error))
            )
            .subscribe((_result: any) => {
                observer.next(note);
                observer.complete();
            });
        });
    }

    /**
     * Edit a Note for a given Funding Request
     */
    update(requestId: string, noteId: number, newComment: string, editTimestamp: number = null): Observable<Note> {
        const endpoint = `${this.api.replace('{:id}', requestId)}/${noteId}`;
        const data = {
            comment: newComment,
            timestamp: editTimestamp ? editTimestamp : new Date().getTime()
        };
        return requestId && noteId ?
            this.http.post<any>(endpoint, data).pipe(
                // Adapt each item in the raw data array to a Note object
                map(response => this.adapter.adapt(response.note)),
            ) :
            of<Note>();
    }

    /**
     * Delete a Note for a given Funding Request
     */
    delete(requestId: string, noteId: number): Observable<boolean> {
        const endpoint = `${this.api.replace('{:id}', requestId)}/${noteId}`;
        return new Observable((observer: any) => {
            this.http.delete<any>(endpoint).pipe(
                catchError(error => observer.error(error))
            )
            .subscribe((_result: any) => { observer.next(true); observer.complete(); });
        });
    }
}
