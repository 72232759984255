import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, expand, map, reduce } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { FundingRequest, FundingRequestAdapter } from '../models';


@Injectable()
export class FundingRequestService {
    private api: string;

    constructor(private http: HttpClient, private adapter: FundingRequestAdapter) {
        this.api = `${environment.apiRoot}${environment.apiEndpoints.fundingRequests}`;
    }

    /**
     * Get all Funding Requests with a given status
     */
    list(filter: string = null, minTime: number = null, maxTime: number = null): Observable<FundingRequest[]> {
        const params = [];
        if (filter && filter !== 'all') {
            params.push(`status=${filter}`);
        }
        if (minTime) {
            params.push(`min=${minTime}`);
        }
        if (maxTime) {
            params.push(`max=${maxTime}`);
        }
        const endpoint = `${this.api}?${params.join('&')}`;

        return new Observable((observer: any) => {
            this.getItems(endpoint).pipe(
                expand((data, _i) => {
                    return data.next ? this.getItems(endpoint, data.next.id, data.next.time) : of();
                }),
                reduce((acc, data) => {
                    return acc.concat(data.results);
                }, []),
                catchError(error => observer.error(error))
            )
            .subscribe((fundingRequests) => {
                observer.next(fundingRequests);
                observer.complete();
            });
        });
    }

    private getItems(endpoint: string, startId: string = null,
                    startTime: number = null): Observable<{next: any, results: FundingRequest[]}> {
        if (startId) {
            endpoint += `&startId=${startId}`;
        }
        if (startTime) {
            endpoint += `&startTime=${startTime}`;
        }
        return this.http.get<any>(endpoint).pipe(
            map(response => {
                let nextIds = null;
                if ('lastEvaluatedKey' in response) {
                    nextIds = {
                        id: response.lastEvaluatedKey.PK,
                        time: response.lastEvaluatedKey.submitTimestamp || response.lastEvaluatedKey.decisionTimestamp || null
                    };
                }
                return {
                    next: nextIds,
                    results: response.applications.map((item: any) => this.adapter.adapt(item))
                };
            })
        );
    }

    /**
     * Get a single Funding Request
     */
    get(id: string): Observable<FundingRequest> {
        // TDOD: handle lookup by id and requestId (e.g. FYxx-nnnn)
        const endpoint = `${this.api}/${id}`;
        return id ?
            this.http.get<any>(endpoint).pipe(
                // Adapt each item in the raw data array to a FundingRequest object
                map(response => this.adapter.adapt(response.application)),
            ) : of();
    }

    changeChild(id: string, childId: string, version: number): Observable<FundingRequest> {
        const endpoint = `${this.api}/${id}`;
        const data = {
            childId: childId,
            version: version
        };
        return id ?
            this.http.post<any>(endpoint, data).pipe(map(response => this.adapter.adapt(response.application))) : of();
    }

    update(id: string, updateParams: Object, version: number = null): Observable<FundingRequest> {
        const endpoint = `${this.api}/${id}`;
        if (!('version' in updateParams)) {
            if (version) {
                updateParams['version'] = version;
            } else {
                const message = `No version was provided while trying to update: ${id}`;
                throw new Error(message);
            }
        }
        return id ?
            this.http.post<any>(endpoint, updateParams).pipe(
                map(response => this.adapter.adapt(response.application)),
            ) : of();
    }

}
