import { Injectable } from '@angular/core';
import { Adapter } from './adapter';
import { ALL_RELATIONSHIPS } from './values/relationship.model';

interface IApplicant {
    name: string;
    phone: string;
    email: string;
    relationshipId: number;
    relationshipOther: string;
}

export class Applicant {
    public name: string;
    public phone: string;
    public email: string;
    public relationshipId: number;
    public relationshipOther: string;

    constructor(obj?: IApplicant) {
        this.name = obj && obj.name || '';
        this.phone = obj && obj.phone || '';
        this.email = obj && obj.email || '';
        this.relationshipId = obj && obj.relationshipId || -1;
        this.relationshipOther = obj && obj.relationshipOther || '';
    }

    get relationship(): string {
        if (this.relationshipId > 0) {
            if (this.relationshipId === 3) {
                return this.relationshipOther;
            } else {
                return ALL_RELATIONSHIPS.find(r => r.id === this.relationshipId).name;
            }
        }
        return '';
    }
}

@Injectable()
export class ApplicantAdapter implements Adapter<Applicant> {

  adapt(item: any): Applicant {
    return new Applicant({
        name: item && item.name || '',
        phone: item && item.phone || '',
        email: item && item.email || '',
        relationshipId: item && item.relationshipId || -1,
        relationshipOther: item && item.relationshipOther || ''
    });
  }
}
