import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { LoginService, LoginResult } from '../services';

@Injectable({providedIn: 'root'})
export class AdminGuard  {

    constructor(private loginService: LoginService) { }

    canActivate(): Observable<boolean> {
        return this.loginService.isAuthenticatedAdmin();
        /*return new Observable(obs => {
            this.loginService.isAuthenticatedAdmin().subscribe(
                result => obs.next(result),
                err => {
                    console.error('[auth.guard]', err);
                    obs.next(false);
                }
            );
        });*/
    }
}
