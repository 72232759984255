import { Component, OnInit, Input } from '@angular/core';

export class Breadcrumb {
    constructor(
        public name: string,
        public icon: string,
        public route: string[] | null
    ) {}
}

@Component({
    selector: 'app-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
    @Input() heading!: string;
    @Input() icon!: string;
    @Input() breadcrumbs!: Breadcrumb[];
    constructor() {}

    ngOnInit() {}
}
