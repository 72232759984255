<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">New User</h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="activeModal.close('cancel')"></button>
</div>
<form [formGroup]="newUserForm" (ngSubmit)="createUser()">
    <div class="modal-body">
        <div *ngIf="errorMessage" class="alert alert-danger" role="alert">
            <span>{{ errorMessage }}</span>
        </div>

        <mdb-form-control class="mx-4 mb-4">
            <input mdbValidate mdbInput type="text" id="username" formControlName="username" class="form-control" required />
            <label mdbLabel class="form-label" for="username">Username</label>
            <mdb-error *ngIf="username?.invalid && (username?.dirty || username?.touched)">
                <span>A username is required</span>
            </mdb-error>
        </mdb-form-control>

        <mdb-form-control class="mx-4 mb-4">
            <input mdbValidate mdbInput type="text" id="email" formControlName="email" class="form-control" required />
            <label mdbLabel class="form-label" for="email">Email</label>
            <mdb-error *ngIf="email?.invalid && (email?.dirty || email?.touched)">
                <span>A valid email address is required</span>
            </mdb-error>
        </mdb-form-control>

        <mdb-form-control class="mx-4 mb-4">
            <input mdbValidate mdbInput type="text" id="name" formControlName="name" class="form-control" required />
            <label mdbLabel class="form-label" for="name">Name</label>
            <mdb-error *ngIf="name?.invalid && (name?.dirty || name?.touched)">
                <span>A Name is required</span>
            </mdb-error>
        </mdb-form-control>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger" (click)="activeModal.close('cancel')">Cancel</button>
        <button type="submit" class="btn btn-primary" [disabled]="!newUserForm.valid">Create</button>
    </div>
</form>
