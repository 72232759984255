import { Constant } from './constant';

export const ALL_PLACEMENTS: Constant[] = [
    { id: 2, name: 'DCF foster home or kinship placement' },
    { id: 6, name: 'Kinship placement' },
    { id: 7, name: 'DCF foster home' },
    { id: 3, name: 'Comprehensive foster care (CFC) home' },
    { id: 1, name: 'Group home or residential program' },
    { id: 5, name: 'Other: specify' },
    { id: 4, name: 'I’m not sure' },
    { id: -1, name: 'Not Specified' },
];
