import { Injectable } from '@angular/core';
import { Adapter } from './adapter';
import { Child, ChildAdapter } from './child.model';
import { Decision, DecisionAdapter } from './decision.model';
import { SocialWorker, SocialWorkerAdapter } from './social-worker.model';
import { Applicant, ApplicantAdapter } from './applicant.model';

export class FundingRequest {
    constructor(
        public requestId: string,
        public id: string,
        public amount: string,
        public dateNeeded: Date,
        public description: string,
        public isChildWorking: boolean,
        public childContribution: string,
        public otherFunding: string,
        public recipient: string,
        public submitDate: Date,
        public status: string,
        public version: number,
        public isOpen: string,
        public childId: string,
        public childName: string,
        public wordpressId: number,
        public child: Child,
        public decision: Decision,
        public socialWorker: SocialWorker,
        public applicant: Applicant,
        public assignee: string,
    ) {
        // Add cents if none are specified
        if (amount && amount.match(/^\d+$/)) {
            this.amount = `${amount}.00`;
        }
    }

    public isDecided(): boolean {
        return !(this.decision === undefined || this.decision === null || this.decision.isApproved === null);
    }

    public isApproved(): boolean {
        return (this.decision && this.decision.isApproved);
    }
}

@Injectable()
export class FundingRequestAdapter implements Adapter<FundingRequest> {
  DATE_REGEX = /\d{4}-\d{1,2}-\d{1,2}/;
  EST_OFFSET = 5 * 60 * 60 * 1000;  // 5 hours

  adapt(item: any): FundingRequest {
    return new FundingRequest(
        item.requestId || null,
        item.id || '',
        item.amount || null,
        item.dateNeeded ? this.getOffsetDate(item.dateNeeded) : null,
        item.description || '',
        (item && 'isChildWorking' in item) ? item.isChildWorking : null,
        item.childContribution || '',
        item.otherFunding || '',
        item.recipient || '',
        item.submitTimestamp ? new Date(item.submitTimestamp) : null,
        item.status || null,
        item.version || -1,
        item.isOpen,
        item.childId || null,
        item.childName || '',
        item.wordpressId || null,
        item.child ? new ChildAdapter().adapt(item.child) : new Child(),
        item.decision ? new DecisionAdapter().adapt(item.decision) : new Decision(),
        item.socialWorker ? new SocialWorkerAdapter().adapt(item.socialWorker) : new SocialWorker(),
        item.applicant ? new ApplicantAdapter().adapt(item.applicant) : new Applicant(),
        item.assignee || ''
    );
  }

  getOffsetDate(dateStr: string): Date {
    if (this.DATE_REGEX.test(dateStr)) {
        // If in YYYY-MM-DD format, we should assume the date is relative to EST as we are east coast based.
        // A non-precise way to do this is add 5 hours to the date. Since we only care about the date
        // and not the time, we don't need to account for DST
        return new Date(Date.parse(dateStr) + this.EST_OFFSET);
    }
    return new Date(dateStr);
  }
}
