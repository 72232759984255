import { Constant } from './constant';

export const ALL_CATEGORIES: Constant[] = [
    { id: -1, name: '-- Select Category --' },
    { id: 1, name: 'Physical activity' },
    { id: 2, name: 'Entertainment' },
    { id: 3, name: 'Camp' },
    { id: 4, name: 'Education' },
    { id: 5, name: 'Beauty' },
    { id: 6, name: 'Music & the arts' },
    { id: 7, name: 'Prom' },
    { id: 8, name: 'Apartment set-up' },
    { id: 9, name: 'Other' },
];
