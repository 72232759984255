import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LanguageTranslationModule } from './shared/modules/language-translation/language-translation.module';
import { ToastrModule } from 'ngx-toastr';
import Bugsnag from '@bugsnag/js';
import BugsnagPerformance, { DefaultRoutingProvider } from '@bugsnag/browser-performance';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { UsersModule } from './layout/users/users.module';
import { AppComponent } from './app.component';
import { AuthGuard, AdminGuard, TokenInterceptor, LoginService, UserAdminService, UserAdapter,
    ChildService, ChildAdapter, FundingRequestService, FundingRequestAdapter, NoteService, NoteAdapter } from './shared';

// Initialize BugSnag
Bugsnag.start({
    apiKey: environment.bugSnagApiKey,
    appVersion: environment.version,
    releaseStage: environment.stage,
    enabledReleaseStages: [ 'production', 'staging' ]
});
BugsnagPerformance.start({
    apiKey: environment.bugSnagApiKey,
    appVersion: environment.version,
    releaseStage: environment.stage,
    enabledReleaseStages: [ 'production', 'staging' ],
    routingProvider: new DefaultRoutingProvider((url: URL): string => {
        if (url.pathname.match(/\/funding-requests\/.*/i)) {
            return '/funding-requests/:request-id';
        }
        else if (url.pathname.match(/\/children\/.*/i)) {
            return '/children/:child-id';
        }
        return url.pathname;
    })
});

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        LanguageTranslationModule,
        AppRoutingModule,
        UsersModule,
        ToastrModule.forRoot({
            timeOut: 10000,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
            closeButton: true
        })
    ],
    declarations: [AppComponent],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: ErrorHandler,
            useFactory: ErrorHandlerFactory
        },
        AuthGuard,
        AdminGuard,
        Title,
        LoginService,
        UserAdminService,
        UserAdapter,
        ChildService,
        ChildAdapter,
        FundingRequestAdapter,
        NoteService,
        NoteAdapter,
        FundingRequestService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {}
}

export function ErrorHandlerFactory() {
    return new BugsnagErrorHandler();
}
