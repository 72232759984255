<div [@routerTransition]>
    <div class="row">
        <div class="col-md-8">
            <app-page-header [heading]="'Users'" [breadcrumbs]="pageTrail"></app-page-header>
        </div>
        <div class="col-md-4 d-flex align-items-center justify-content-end">
            <div class="float-end me-1">
                <button class="btn btn-sm btn-primary" (click)="openNewUserModal()">
                    {{ 'New User' | translate }}
                </button>
            </div>
            <div class="float-end me-1">
                <div class="btn-group" role="group">
                    <input type="radio" class="btn-check" name="displayInactive" id="hide" value="hide" autocomplete="off" checked (change)="displayInactive=false;" />
                    <label class="btn btn-sm btn-secondary" for="hide">Hide Inactive</label>

                    <input type="radio" class="btn-check" name="displayInactive" id="show" value="show" autocomplete="off" (change)="displayInactive=true;" />
                    <label class="btn btn-sm btn-secondary" for="show">Show Inactive</label>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="table-sm table-hover table-responsive clearfix" *ngIf="users; else loading">
            <table class="table align-middle mb-0 bg-white">
                <thead class="bg-light">
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">{{ 'Role' | translate }}</th>
                        <th scope="col">{{ 'Username' | translate }}</th>
                        <th scope="col">{{ 'Name' | translate }}</th>
                        <th scope="col">{{ 'Email' | translate }}</th>
                        <th scope="col">{{ 'Login' | translate }}</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let user of users | callback: displayUser">
                        <td>
                            <button [routerLink]="['/users', user.username]" [routerLinkActive]="['router-link-active']" type="button" class="btn btn-link btn-rounded btn-sm fw-bold" mdbRipple rippleColor="dark">Edit</button>
                        </td>
                        <td>
                            <span *ngIf="user.admin; else userbadge" class="badge badge-success rounded-pill d-inline">Admin</span>
                            <ng-template #userbadge>
                                <span class="badge badge-primary rounded-pill d-inline">User</span>
                            </ng-template>
                        </td>
                        <td class="align-middle">{{ user.username }}</td>
                        <td class="align-middle">{{ user.getName() }}</td>
                        <td class="align-middle">{{ user.getEmail() }}</td>
                        <td class="align-middle">
                            <div class="form-check form-switch">
                                <input mdbCheckbox class="form-check-input" type="checkbox" [checked]="user.enabled" (change)="enableLogin(user, !user.enabled)" title="Enable or disable user login" />
                            </div>
                        </td>
                        <td class="align-middle" scope="row">
                            <div *ngIf="user.status != 'FORCE_CHANGE_PASSWORD'; else resend">
                                <button type="button" class="btn btn-link btn-rounded btn-sm fw-bold" mdbRipple rippleColor="dark" [disabled]="!user.enabled" (click)="resetPassword(user.username)">
                                    {{ 'Reset Password' | translate }}
                                </button>
                            </div>
                            <ng-template #resend>
                                <button type="button" class="btn btn-link btn-rounded btn-sm fw-bold" mdbRipple rippleColor="dark" [disabled]="!user.enabled" (click)="resendTempPassword(user.username)" [title]="'Resend Temporary Password'|translate">
                                    {{ 'Resend Temporary Password' | translate }}
                                </button>
                            </ng-template>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ng-template #loading>
            <p>{{'Loading' | translate}}...</p>
        </ng-template>
    </div>
</div>
