import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

import { routerTransition } from '../../../router.animations';
import { UserAdminService } from '../../../shared/services';

@Component({
    selector: 'app-new-user-modal',
    templateUrl: './new-user-modal.component.html',
    styleUrls: ['./new-user-modal.component.scss'],
    animations: [routerTransition()]
})
export class NewUserModalComponent {
    @Input() id: number;
    errorMessage: string;
    newUserForm: UntypedFormGroup;

    constructor(
        public activeModal: MdbModalRef<NewUserModalComponent>,
        private userAdminService: UserAdminService,
        private formBuilder: UntypedFormBuilder
    ) {
        // Setup form
        this.createForm();
        this.errorMessage = null;
    }

    public createUser(): void {
        const vals = this.newUserForm.value;
        this.userAdminService.createUser(vals.username, vals.email, vals.name).subscribe(
            _result => {
                // TODO: Pass back new user object
                this.activeModal.close();
            },
            err => {
                console.error(err);
                this.errorMessage = err.message;
            }
        );
    }

    /* Form attributes */

    get username(): AbstractControl {
        return this.newUserForm.get('username')!;
    }

    get name(): AbstractControl {
        return this.newUserForm.get('name')!;
    }

    get email(): AbstractControl {
        return this.newUserForm.get('email')!;
    }

    private createForm(): void {
        this.newUserForm = this.formBuilder.group({
            username: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            name: ['', Validators.required],
        });
    }

}
