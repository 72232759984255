import { Constant } from './constant';

export const ALL_RACE_ETHNICITIES: Constant[] = [
    { id: 5, name: 'White' },
    { id: 8, name: 'Hispanic/Latino/Latina' },
    { id: 3, name: 'Black' },
    { id: 2, name: 'Asian' },
    { id: 1, name: 'Native American' },
    { id: 4, name: 'Pacific Islander' },
    { id: 9, name: 'Multi-Racial' },
    { id: 10, name: 'Decline' },
    { id: 7, name: 'Unknown' },
    { id: 6, name: 'Not listed above' },
    { id: -1, name: 'Not Specified' },
];
