import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { Observable, zip } from 'rxjs';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';

import { environment } from '../../../environments/environment';
import { routerTransition } from '../../router.animations';
import { TITLE_COMMON } from '../../shared';
import { UserAdminService } from '../../shared/services';
import { NewUserModalComponent } from './new-user-modal/new-user-modal.component';
import { User } from '../../shared/models';
import { Breadcrumb } from '../../shared/modules/page-header/page-header.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  animations: [routerTransition()]
})
export class UsersComponent implements OnInit {
    private modalRef: MdbModalRef<NewUserModalComponent> | null = null;
    public displayInactive: boolean;
    users: User[];
    pageTrail: Breadcrumb[];

    // Used to toggle enabled/disabled users being shown via callback pipe
    displayUser = (user: User) => {
        return user.enabled || this.displayInactive;
    };

    constructor(
        private userAdminService: UserAdminService,
        private titleService: Title,
        private toastr: ToastrService,
        private modalService: MdbModalService
    ) {
        this.pageTrail = [new Breadcrumb('Users', 'users', null)];
        this.titleService.setTitle('User Management' + TITLE_COMMON);
        this.displayInactive = false;
    }

    ngOnInit(): void {
        this.loadUsers();
    }

    private loadUsers(): void {
        const admins = this.userAdminService.getUsersInGroup('Administrators');
        const devs = this.userAdminService.getUsersInGroup('Development');
        const users = this.userAdminService.list();
        
        zip(admins, devs, users).subscribe({
            next: data => {
                const adminUsers = data[0];
                const devUsers = data[1];
                let users = data[2];

                this.users = users.filter(user => (environment.stage === 'production' && !devUsers.includes(user.username)) || environment.stage !== 'production')
                                  .map((user: User) => Object.assign(user, {admin: adminUsers.includes(user.username)}));
            }
        });
    }

    public openNewUserModal() {
        this.modalRef = this.modalService.open(NewUserModalComponent);
        this.modalRef.onClose.subscribe({
            next: (result: any) => {
                if (result) {
                    this.loadUsers(); // Update user list
                }
            },
            error: (err: any) => {
                if (err) {
                    console.log(err);
                }
            }
        });
    }

    public enableLogin(user: User, isEnabled: boolean): void {
        let obs: Observable<boolean>;
        if (isEnabled) {
            obs = this.userAdminService.enableUser(user.username);
        } else {
            obs = this.userAdminService.disableUser(user.username);
        }
        const action = isEnabled ? 'enable' : 'disable';
        obs.subscribe({
            next: (_result: boolean) => {
                this.toastr.success(`${user.username}'s login was successfully ${action}d`);
                user.enabled = isEnabled;
            },
            error: (err: any) => {
                console.error(err);
                this.toastr.error(`Error while attempting to ${action} ${user.username}'s login`);
            }
        });
    }

    public resetPassword(username: string): void {
        this.userAdminService.resetUserPassword(username).subscribe(
            (success: boolean) => {
                if (success) {
                    this.toastr.success(`${username}'s password was successfully reset`, 'Password Reset');
                } else {
                    this.toastr.error(`Error while attempting to reset ${username}'s password`, 'Password Reset Failed');
                }
            }
        );
    }

    public resendTempPassword(username: string): void {
        this.userAdminService.resendNewUserTempLogin(username).subscribe({
            next: (_result: User) => {
                this.toastr.success(`${username}'s temporary new user password was resent`);
            },
            error: (err: any) => {
                console.error(err);
                this.toastr.error(`Error while attempting to resend ${username}'s temporary new user password`);
            }
        });
    }
}
