import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
    if(window) {
        // Disable debug console output in production
        window.console.debug=function(){};
    }
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
