import { Constant } from './constant';

export const ALL_RELATIONSHIPS: Constant[] = [
    { id: 1, name: 'Youth- I’m applying for me!' },
    { id: 2, name: 'Foster parent' },
    { id: 4, name: 'Case worker or clinician' },
    { id: 5, name: 'DCF social worker' },
    { id: 3, name: 'Other support (specify)' },
    { id: -1, name: 'Not Specified' },
];
