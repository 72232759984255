import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable, zip } from 'rxjs';

import { routerTransition } from '../../../router.animations';
import { TITLE_COMMON } from '../../../shared';
import { UserAdminService } from '../../../shared/services';
import { User } from '../../../shared/models';
import { Breadcrumb } from '../../../shared/modules/page-header/page-header.component';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
  animations: [routerTransition()]
})
export class UserDetailComponent implements OnInit {
    private username: string;
    title: string;
    user: User;
    userForm: UntypedFormGroup;
    pageTrail: Breadcrumb[];

    constructor(
        private route: ActivatedRoute,
        private userAdminService: UserAdminService,
        private titleService: Title,
        private toastr: ToastrService,
        private formBuilder: UntypedFormBuilder
    ) {
        this.pageTrail = [new Breadcrumb('Users', 'users', ['/users'])];

        // Setup form
        this.createForm();
    }

    ngOnInit() {
        this.username = this.route.snapshot.paramMap.get('username');
        const user = this.userAdminService.getUser(this.username);
        const groups = this.userAdminService.getUserGroups(this.username);

        zip(user, groups).subscribe({
            next: (resp: [User, string[]]) => {
                this.user = resp[0];

                const groups = resp[1];
                groups.includes('Administrators') ? this.user.admin = true : this.user.admin = false;
                groups.includes('Development') ? this.user.dev = true : this.user.dev = false;

                this.userForm.patchValue(this.user);

                // Add breadcrumb to page trail
                this.pageTrail.push(new Breadcrumb(this.user.getName(), 'edit', null));
                this.title = this.user.getName();
                this.titleService.setTitle(`${this.user.getName()}: User Profile` + TITLE_COMMON);
            }
        });
    }

    onSubmit(): void {
        const formAttrs = this.userForm.get('attributes');
        const attributes = {
            name: formAttrs.get('name').value,
            email: formAttrs.get('email').value
        };
        this.userAdminService.updateUserAttributes(this.username, attributes).subscribe({
           next: _result => {
                this.toastr.success('User Updated');
            },
            error: (err: any) => {
                console.error(err);
                this.toastr.error('Failed to update user details');
            }
        });
    }

    public enableLogin(enableLogin: boolean): void {
        let obs: Observable<boolean>;
        if (enableLogin) {
            obs = this.userAdminService.enableUser(this.username);
        } else {
            obs = this.userAdminService.disableUser(this.username);
        }
        const action = enableLogin ? 'enable' : 'disable';
        obs.subscribe({
            next: _results => {
                this.user.enabled = enableLogin;
                this.toastr.success(`${this.username}'s login was successfully ${action}d`);
            },
            error: (err: any) => {
                console.error(err);
                this.toastr.error(`Error while attempting to ${action} ${this.username}'s login`);
            }
        });
    }

    public enableAdmin(enableAdmin: boolean): void {
        let obs: Observable<boolean>;
        if (enableAdmin) {
            obs = this.userAdminService.makeUserAdmin(this.username);
        } else {
            obs = this.userAdminService.removeUserAdmin(this.username);
        }
        const phrase = enableAdmin ? 'now' : 'no longer';
        obs.subscribe({
            next: _result => {
                this.user.admin = enableAdmin;
                this.toastr.success(`${this.username} is ${phrase} an Administrator`);
            },
            error: (err: any) => {
                console.error(err);
                this.toastr.error(`Error while changing administrator status for ${this.username}`);
            }
        });
    }

    /* Form attributes */

    get name(): AbstractControl {
        return this.userForm.get('attributes').get('name')!;
    }

    get email(): AbstractControl {
        return this.userForm.get('attributes').get('email')!;
    }

    private createForm(): void {
        this.userForm = this.formBuilder.group({
            enabled: [''], // TODO boolean
            created: [''], // TODO date
            modified: [''], // TODO date
            attributes: this.formBuilder.group({
                name: ['', Validators.required],
                phone: [''], // TODO phone
                email: ['', Validators.email]
            }),
        });
    }
}
