import { Constant } from './constant';

export const ALL_RACES: Constant[] = [
    { id: 1, name: 'American Indian or Alaskan Native' },
    { id: 2, name: 'Asian' },
    { id: 3, name: 'Black or African American' },
    { id: 4, name: 'Native Hawaiian / Pacific Islander' },
    { id: 5, name: 'White' },
    { id: 6, name: 'Other' },
    { id: 7, name: 'Unknown' },
];
