import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { LoginService } from '../services';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(private loginService: LoginService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return from(this.loginService.getAPIToken())
            .pipe(
                switchMap(authToken => {
                    const authReq = (authToken) ? request.clone({ setHeaders: { Authorization: authToken } }) : request;
                    return next.handle(authReq);
                })
            );
    }

}
