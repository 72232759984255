import { Injectable } from '@angular/core';
import { Adapter } from './adapter';

export class Note {
    constructor(
        public date: Date,
        public editDate: Date,
        public userId: string,
        public comment: string
    ) { }
}

@Injectable()
export class NoteAdapter implements Adapter<Note> {

    adapt(item: any): Note {
        return new Note(
            item.timestamp ? new Date(item.timestamp) : null,
            item.editTimestamp ? new Date(item.editTimestamp) : null,
            item.username || '',
            item.comment || ''
        );
    }

    adaptMany(items: any[]): Note[] {
        const notes = new Array<Note>();
        for (const item of items) {
            notes.push(this.adapt(item));
        }
        return notes;
    }
}
